import ReactDOM from 'react-dom/client'
// @ts-ignore
import {registerSW} from 'virtual:pwa-register';

import App from './App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App/>)

if ('serviceWorker' in navigator) {
    registerSW();
}
